.vehicle-list {
  margin-top: 8px;
  margin-bottom: 56px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 48px;
}
.view-more-button {
  margin-bottom: 24px;
}
.vehicle-card {
  background-color: #f8f7f8;
  display: flex;
  justify-content: space-between;
  padding: 30px 20px;
  border-radius: 12px;
}
.vehicle-card:hover {
  transform: scale(1.05);
  transition: transform 0.7s ease-in-out;
}
.vehicle-img {
  background-color: #fffdf3;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
  overflow: hidden;
  margin-left: -40px;
  aspect-ratio: 1 / 1;
}
.vehicle-image {
  max-width: 80%;
  max-height: 80%;
}
.vehicle-info {
  margin-right: 80px;
}
.vehicle-info h2 {
  font-size: 18px;
  margin: 0;
}
.vehicle-info p {
  margin: 0;
  color: #999;
}
.view-more-button {
  border: none;
  background: transparent;
  color: #a46704;
  text-decoration: underline;
}
.show-more-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}

@media (max-width: 320px) {
  .vehicle-img {
    width: 70px;
    height: 70px;
    margin-left: -60px;
  }
  .vehicle-image {
    max-width: 60%;
    max-height: 60%;
  }
}

@media (min-width: 880px) and (max-width: 935px) {
  .vehicle-img {
    width: 70px;
    height: 70px;
    margin-left: -90px;
  }
  .vehicle-image {
    max-width: 60%;
    max-height: 60%;
  }
}
