.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  /* height: calc(100vh - 60px); */
  scrollbar-width: none;
  width: 250px;
  background-color: #231F20;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* min-height: 100vh; */
  /* padding: 20px 15px; */
  padding: 20px 15px;
  z-index: 100;
  transition: transform 0.3s ease;
  transform: translateX(0);
}

.logo img {
  width: 150px;
  margin-bottom: 30px;
}

.nav-menu ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li img {
width: 25px;
}

.nav-link {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  /* padding: 15px 10px; */
  padding: 4px 10px;
  font-size: 16px;
  border-radius: 6px;
  margin-bottom: 10px;
}

.nav-link .icon {
  margin-right: 10px;
}

.active-link {
  background-color: #FFCC00;
}

/* .nav-link.active, */
.nav-link:hover {
  background-color: #FFCC00;
  color: black;
}

.invest-cta {
  background-color: #1DE83E;
  color: black;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  font-size: 14px;
}

.invest-cta h4 {
  margin: 0;
  margin-bottom: 4px;
}

.invest-cta button {
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 5px;
}

.invest-cta button:hover {
  background-color: white;
  color: black;
}

/* Sidebar hidden by default on small screens */
.sidebar.hidden {
  transform: translateX(-100%);
}

.sidebar.visible {
  transform: translateX(0);
  height: 100vh;
  position: fixed;
}

@media (max-width: 768px) {
  .sidebar {
    width: 200px;
    transform: translateX(-100%);
  }

  .sidebar.visible {
    transform: translateX(0);
  }

  .nav-link {
    font-size: 14px;
  }
}


