/* Referral Container */
.referral-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  margin: 50px 0;
  /*  */
  width: 100%;
  max-width: 400px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  
}

.referral-container h2 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px;
}

/* Referral Link Box */
/* .referral-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 15px 20px;
  max-width: 80%;
  width: 400px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
} */

.referral-link {
  font-size: 1rem;
  color: #555;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  word-wrap: break-word; 
  overflow-wrap: break-word; 
  width: 100%; 
  box-sizing: border-box; 
  margin-bottom: 15px;
  text-align: center;
}

/* Buttons */
.copy-button, .share-button {
  display: flex;
  align-items: center;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px 0;
  font-size: 1rem;
  color: #fff;
}

.copy-button {
  background-color: #4caf50;
}

.share-button {
  background-color: #1e88e5;
}

.copy-button:hover, .share-button:hover {
  opacity: 0.9;
}

.copy-button:active, .share-button:active {
  transform: scale(0.98);
}


/* Referral Bonus History */
/* .referral-history {
  margin-top: 20px;
  width: 100%;
  max-width: 400px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  text-align: center;
} */
.referral-history {
  margin-top: 10px;
}
.referral-history h3 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px;  
}

.bonus-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.bonus-item {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 1rem;
  color: #555;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.bonus-item strong {
  color: #1e88e5;
}


/* Mobile Adjustments */
@media (max-width: 600px) {
  .referral-container {
    margin-bottom: -60px;
  }
  .referral-container h3 {
    font-size: 1rem;
  }

  .referral-box {
    padding: 15px;
    width: 90%;
    max-width: 100%;
  }

  .referral-link {
    font-size: 0.9rem;
    padding: 8px;
  }

  .copy-button, .share-button {
    padding: 7px 12px;
    font-size: 0.9rem;
  }

  .referral-history {
    width: 90%;
    max-width: 100%;
  }
}


