.investment-carousel {
  text-align: center;
  padding: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0 80px;
  border: 1px solid #E7E7E7;
}

/* Investment title styles */
.investment-title {
  font-size: 1.5rem;
  font-weight: bold;
}

/* Investment subtitle styles */
.investment-subtitle {
  font-size: 1rem;
  color: #888;
  margin-bottom: 30px;
}

/* Carousel container adjustments */
.investment-carousel-container {
  display: flex !important;
  flex-direction: row ;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: relative;
}

/* Investment cards container styles */
.investment-cards {
  display: flex !important;
  justify-content: center !important;
  gap: 30px !important;
  overflow: hidden; /* Hide overflow content */
}

/* Active card should be highlighted and centered */
.investment-card {
  text-align: center;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  cursor: pointer;
  min-width: 300px;
  opacity: 0.5; 
}

.investment-card.active {
  opacity: 1;
  transform: scale(1.05); 
}

.investment-card img {
  width: 100px;
  height: auto;
}

.investment-card h3 {
  font-size: 1.2rem;
  margin: 10px 0 5px;
}

.investment-card p {
  font-size: 1.1rem;
  font-weight: bold;
}

.active {
  opacity: 1 !important;
}

/* Arrow button styling */
.carousel-arrow {
  font-size: 2rem;
  background-color: #888;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #000;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  z-index: 1;
}

.carousel-arrow:hover {
  background-color: #ffaa00;
}

.left-arrow {
  left: 50px;
}

.right-arrow {
  right: 50px;
}

/* Continue button styling */
.investment-continue-button {
  margin-top: 40px;
  margin-bottom: 30px;
  padding: 15px 50px;
  background-color: #ffcc00;
  border: none;
  color: #fff;
  font-size: 1.2rem;
  border-radius: 5px;
  cursor: pointer;
}

.investment-continue-button:hover {
  background-color: #ffaa00;
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
  .investment-carousel {
    margin: 0 10px; 
  }

  .investment-carousel-container {
    gap: 10px;
  }

  .investment-cards {
    gap: 0; 
    justify-content: center;
  }

  .investment-card {
    min-width: 90%; 
  }

  .investment-card img {
    width: 80%; 
  }

  .left-arrow {
    left: 5px;
  }

  .right-arrow {
    right: 5px;
  }

  .investment-continue-button {
    width: 100%;
    padding: 10px;
  }
}
