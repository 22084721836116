.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 60px;
  background-color: #FFE843;
  position: fixed;
  top: 0;
  left: 250px;
  right: 0;
  z-index: 999;
  transition: left 0.3s ease; 
  /* margin-top: 70px; */
}

.right-side {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-right {
  display: flex;
  align-items: center;
}

.header-logo {
  width: 100px;
  height: 50px;
}

.header-logo img {
  display: none;
  width: 100%;
  height: 100%;
}

.notification-link {
  margin-right: 20px;
}

.notification-icon {
  height: 25px;
  cursor: pointer;
}

.profile-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
}

.profile-image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  cursor: pointer;
}

.profile-greeting {
  font-weight: normal;
  color: #CE9100;
}

.profileName {
  color: #734110;
} 


/* Additional styles for the menu icon */
.menu-icon {
  display: none;
  cursor: pointer;
}

/* Adjust the header when sidebar is hidden */
@media (max-width: 768px) {
  .header {
    left: 0;
  }

  .notification-icon {
    height: 20px;
  }

  .profile-image {
    height: 30px;
    width: 30px;
  }

  .profile-greeting {
    display: none; /* Hide the name on smaller screens */
  }
  .menu-icon {
    display: block;
  }

  .logo img {
    height: 30px;
  }

  .header-logo img {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
}