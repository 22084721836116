.transaction-list-container {
  position: relative;
  padding: 20px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  max-width: 1000px;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.filters {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.filter-button {
  background-color: #00D100;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin-right: 20px;
  cursor: pointer;
}

.to {
  margin: 0 20px;
}

.date-filters select {
  margin: 0 10px;
  padding: 5px;
}

.transaction-table {
  width: 100%;
  border-collapse: collapse;
}

.transaction-table th,
.transaction-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.transaction-table tbody tr.successful {
  background-color: #f0fff4; 
}

.transaction-table tbody tr.ongoing {
  background-color: #fff4f4; 
}

.pagination-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
}

.pagination-controls select {
  padding: 5px;
}

/* confirmation message animation css */

.loader-overlay {
  position: fixed; 
  top: 0; 
  left: 0; 
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex; 
  justify-content: center; 
  align-items: center; 
  z-index: 1000; 
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms; 
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms; 
}


/* Responsiveness */
@media (max-width: 768px) {
  .transaction-list-container {
    padding: 10px;
  }

  /* to reduce the select button size on small screen */
  .transaction-list-container select {
    max-width: 100px;
  }

  h1 {
    font-size: 20px;
    text-align: center;
  }

  /* Stack filter and date selectors */
  .filters {
    flex-direction: column;
    align-items: flex-start;
  }

  .filter-button {
    /* width: 100%; */
    margin-bottom: 10px;
  }

  .date-filters {
    /* width: 100%; */
    justify-content: space-between;
  }

  .date-filters select {
    width: 48%;
  } 

  .date-filters span {
    display: block;
    margin-left: 3rem;
  }
  /* Table on small screens */
  .transaction-table {
    display: block; 
    overflow-x: auto; 
  }

  /* Adjust table cells to stack */
  .transaction-table th,
  .transaction-table td {
    padding: 10px;
    font-size: 14px;
    white-space: nowrap; 
  }

  /* Pagination controls */
  .pagination-controls {
    flex-direction: column;
    align-items: center;
  }

  .pagination-controls select {
    margin: 20px 0;
  }

  .disabled-btn {
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  /* Further adjustments for mobile devices */
  h1 {
    font-size: 18px;
  }

  .filter-button,
  .date-filters select {
    font-size: 14px;
    padding: 8px;
  }

  .transaction-table th,
  .transaction-table td {
    padding: 8px;
    font-size: 12px;
  }

  .pagination-controls {
    flex-direction: column;
  }
}