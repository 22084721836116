/* main styles */
.asset-details-container {
  padding: 20px;
  margin: 0 auto;
  max-width: 1200px; 
}

.asset-date {
  display: flex;
  flex-wrap: wrap; 
  align-items: center;
  justify-content: start;
}

.asset-name {
  margin-right: 44px;
}

.asset-end-start p {
  margin-right: 20px;
  margin-bottom: 10px;
  font-size: 20px;
  padding: 15px 30px;
  font-weight: 500;
  background-color: #f8f7f8;
}

h1 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #231f20;
}

.asset-image {
  max-width: 300px;
  height: auto;
  margin-bottom: 20px;
}

.asset-details-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px; 
  margin: 50px 0;
}

.asset-financials {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.asset-financials-details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
 
.asset-financials-details p span,
.asset-financials-weekly p span {
  display: block;
  color: #1de83e;
  font-size: 1.3rem;
  margin-top: 5px;
}

.asset-financials-details p {
  background-color: #effef1;
  /* padding: 15px 60px; */
  font-size: 0.8rem;
  padding: 8px 10px;
  font-weight: bold;
  color: #0e141c;
}

.asset-financials-details p span {
  font-size: 1rem;
}

.asset-description-heading {
  font-weight: bold;
  font-size: 0.8rem;
  color: #1de83e;
  display: block;
}

.asset-description {
  background-color: #effef1;
  color: #0e141c;
  font-size: 11px;
  padding: 12px 24px;
}

.asset-financials-weekly {
  margin-top: 10px;
}

.asset-financials-weekly p {
  font-weight: bold;
  padding: 15px 0;
  text-align: center;
  background-color: #fefde8;
}

/* .confirm-investment {
  width: 100%;
} */

.confirm-investment button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  background-color: #00ff00;
  color: white;
  font-size: 16px;
}

.confirm-investment button:hover {
  background-color: #7fdb7f;
}

/* CSS for the overlay to confirm investment */
/* Make the overlay cover the entire viewport */
.confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6); 
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto; 
  z-index: 1000;
}

/* Center the content within the overlay */
.confirmation-card {
  background: #fff;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh; 
  overflow-y: auto; 
  border-radius: 8px;
  color: #333; 
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); 
}

/* Add padding to the content to improve readability */
.contract-terms {
  padding: 10px;
  font-size: 0.9rem;
  color: #555;
}

/* Checkbox and dropdown adjustments */
.agreement-checkbox {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 0.9rem;
}

#payoutInterval {
  margin-top: 15px;
  padding: 5px;
  font-size: 0.9rem;
  width: 100%;
}



.error-message {
  color: red;
  font-size: 0.9rem;
  margin-top: 10px;
}

.cont-invest-agreement {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #00ff00;
  color: white;
  font-size: 16px;
  margin-right: 20px;
}

.cancel-invest-agreement {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #FF3737;
  color: white;
  font-size: 16px;
  margin-right: 20px;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .asset-details-info {
    grid-template-columns: 1fr; 
    margin: 50px 0;
  }

  .asset-financials-details {
    grid-template-columns: 1fr;
  }

  .asset-image {
    max-width: 250px;
  }

  h1 {
    font-size: 1.8rem;
  }
}

@media (max-width: 768px) {
  .asset-end-start {
    flex-direction: column; 
    align-items: flex-start;
  }

  .asset-end-start p {
    margin-right: 0;
    width: 100%; 
  }

  .asset-image {
    max-width: 200px; 
  }

  h1 {
    font-size: 1.5rem; 
  }

  .asset-financials-details p {
    padding: 15px 40px; 
  }
}

@media (max-width: 480px) {
  .asset-details-container {
    padding: 10px;
  }

  h1 {
    font-size: 1.3rem; 
  }

  .asset-image {
    max-width: 150px;
  }

  .asset-end-start p {
    font-size: 16px; 
    padding: 10px 20px; 
  }

  .asset-financials-details p {
    padding: 10px 30px; 
  }
}

/* Responsive styling for small screens */
@media (max-width: 480px) {
  .confirmation-card {
    width: 90%;            
    padding: 15px;         
    /* margin: 0 auto;         */
    margin-right: 20px;
    box-sizing: border-box;
  }
}