.security-page {
  max-width: 500px;
  padding: 20px;
  background-color: #f9f9f9;
  border: 0.1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.01);
}

/* .security-page h4 {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
  color: #3a3a3a;
} */

.security-page p {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}

/* .accordion {
  border: none;
  box-shadow: none;
} */

.accordion-item {
  background-color: #fff;
  padding: 20px;
  border: 0.1px solid #ddd;
  margin-bottom: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.01);
}

label {
  display: block;
  font-size: 15px;
  margin-bottom: 10px;
}

input[type="password"], input[type="numeric"], input[type="email"], input[type="text"]{
  width: 100%;
  height: 40px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
}

.security-page button {
  background-color: #1de83e;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 8px;
}

.accordion-button {
  background-color: #b4b2b2 !important;
  width: 100% !important;
  margin-bottom: 10px !important;
  color: black !important;
}

.accordion-button:hover {
background-color: #1de83e !important;
color: #fff !important;
}

.accordion-button:active {
  background-color: #666 !important;
  color: white !important;
}

button:hover {
  background-color: #3e8e41;
}

/* for error and success message */
/* Security.css */

.message {
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
}

.message.success {
  color: green;
}

.message.error {
  color: red;
}
