.logout-page {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: start;
  padding: 20px;
}

.logout-buttons {
  display: flex;
  justify-content: start;
  gap: 20px;
  margin-top: 20px;
}

.logout-button,
.cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.logout-button {
  background-color: #FFCC00;
  color: black;
}

.cancel-button {
  background-color: #ccc;
  color: black;
}

.logout-button:hover,
.cancel-button:hover {
  background-color: #e5b62f;
}