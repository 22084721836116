.assets-page {
  padding: 20px;
  margin: 0 auto;
}

/* Page title */
.assets-page h1 {
  font-size: 2rem;
  font-weight: bold;
}

/* Assets Grid */
.assets-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.assets-grid-2 {
  display: grid;
  grid-template-columns:1fr 1fr ;
}

/* Individual Asset Card */
.asset-card {
  background-color: #e9f7e9;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.asset-card:hover {
  transform: scale(1.05);
}
.asset-card img {
  width: 100%;
  height: auto;
  max-height: 150px;
  object-fit: contain;
}

.asset-card h2 {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 10px 0;
}

.asset-card p {
  font-size: 1rem;
  color: #333;
}

.value span {
  font-size: 20px;
  font-weight: 600;
}