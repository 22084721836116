.kyc-container {
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  /* margin: auto; */
}

.kyc-header {
  margin-bottom: 24px;
}

.kyc-header h4 {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
  color: #3a3a3a;
}

.kyc-steps {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
}

.step {
  font-size: 14px;
  color: #aaa;
}

.step.completed {
  color: #28a745; /* Green for completed */
}

.step.active {
  color: #ffa500; /* Orange for active */
}

.kyc-form {
  display: flex;
  flex-direction: column;
}

.kyc-label {
  margin-bottom: 8px;
  color: #333;
}

.kyc-select, .kyc-input, .kyc-upload-input {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 16px;
}

.kyc-upload {
  border: 2px dashed #ccc;
  padding: 24px;
  text-align: center;
  margin-bottom: 16px;
  border-radius: 8px;
  position: relative;
}

.kyc-upload-label {
  display: block;
  color: #333;
  margin-bottom: 8px;
}

.image-preview img {
  width: 100%;
  max-width: 300px;
  height: auto; 
  border-radius: 5px;
  border: 2px solid #ddd;
  margin-bottom: 10px;
}

.kyc-upload-input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.id-submit-button {
  background-color: #1de83e !important;
  border: none;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #fff;
}

.id-submit-button:hover {
  background-color: #0f5b1c !important;
}

/* error and success message CSS */
.kyc-message {
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
}

.kyc-message.error {
  color: #f44336; 
  background-color: #fdecea; 
  border: 1px solid #f44336;
}

.kyc-message.success {
  color: #4caf50; 
  background-color: #e8f5e9; 
  border: 1px solid #4caf50;
}

.kyc-link {
  background: none;
  border: none;
  /* color: #3a3a3a; */
  color: #1de83e;
  text-decoration: underline;
  cursor: pointer;
  margin-right: 10px;
  font-size: 12px;
}

.kyc-link.active {
  font-weight: bold;
  color: #ffa500;
}

.kyc-link:hover {
  color: #fff;
}

@media (max-width: 768px) {
  .kyc-link{
    font-size: 10px;
  }
}



