.balance-overview {
  background-image: url("../Assets/balance-overview-bg.png");
  background-size: cover;
  color: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  min-width: 260px;
  /* z-index: -10; */
}

.balance-info {
  margin-left: 30px;
}

.balance-info h3 {
  font-size: 18px;
}

.balance-info h2 {
  font-size: 28px;
  margin: 10px 0;
}

.balance-actions {
  display: flex;
  gap: 10px;
}

.withdraw-btn,
.deposit-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.withdraw-btn {
  background-color: #ffc700;
  color: white;
}

.deposit-btn {
  background-color: #00ff00;
  color: white;
}

.withdraw-btn:hover,
.deposit-btn:hover {
  background-color: #fafafa;
  color: #3a3a3a;
}

.balance-toggle {
  position: absolute;
  top: 60px;
  right: 50px;
}

.balance-icon {
  position: relative;
  display: inline-block;
}

.balance-icon::before {
  content: "";
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  /* z-index: -1; */
}

/* deposit */
/* Overlay to block interaction with the rest of the page */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6); 
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal popup for the deposit form */
.deposit-popup {
  background: white;
  padding: 20px 10px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1001;
}

.deposit-popup .paystack-btn, .manual-deposit-btn {
  border: none;
  padding: 10px;
  border-radius: 5px;
  background-color: #218838;
  color: white; 
}

.deposit-popup .paystack-btn {
  margin-right: 10px;
}

.deposit-popup .paystack-btn:hover {
  /* background-color: #00ff00; */
  background-color: #28a745;
  
}

.deposit-popup h3 {
  margin-bottom: 15px;
  font-size: 1.2rem;
  color: #333;
}

.deposit-popup p{
  color: #333;
}
.deposit-popup .manual-deposit-instruction {
  font-size: 0.65rem;
  color: #c82333;
  margin-bottom: 16px;
}

.deposit-popup .manual-deposit-instruction .note{
  color:#333;
  font-size:  1rem;
}

.deposit-popup .account-name, .account-number, .bank-name {
  font-size: 1rem;
  font-weight: bold;
}

.deposit-popup .bank-name-p {
  margin-bottom: 16px;
}

/* .upload-label:hover {
  background-color: #0056b3;
} */

.deposit-input {
  padding: 10px;
  font-size: 1rem;
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
}

.deposit-input:focus {
  border-color: #0a74da;
}

/* Buttons inside the popup */
.popup-buttons {
  display: flex;
  justify-content: space-between;
}

.confirm-deposit-btn{
  padding: 10px 4px;
  font-size: 0.8rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 55%;
  margin-right: 8px;
}

.cancel-btn {
  padding: 10px 0px;
  font-size: 0.8rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 30%;
}

.confirm-deposit-btn {
  background-color: #28a745;
  color: white;
}

.confirm-deposit-btn:hover {
  background-color: #218838;
}

.cancel-btn {
  background-color: #dc3545;
  color: white;
  margin-top: 10px;
}

.cancel-btn:hover {
  background-color: #c82333;
}

/* manual deposit error/success message */
.file-name {
  margin-top: 10px;
  font-size: 14px;
  color: #555;
}

.message {
  margin-top: 15px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}

.message.success {
  background-color: #d4edda;
  color: #155724;
}

.message.error {
  background-color: #f8d7da;
  color: #721c24;
}

 /* responsiveness */
@media (max-width: 768px) {
  .balance-overview {
    padding: 15px;
  }

  .balance-info h2 {
    font-size: 20px;
    /* margin-left: -135px; */
  }

  .balance-info h3 {
    font-size: 12px;
  }

  .balance-toggle {
    top: 40px;
    right: 15px;
    margin-right: 4px;
  }

  .balance-icon::before {
    width: 30px;
    height: 30px;
  }

  .balance-info {
    margin-left: 5px;
  }

  .deposit-popup h3 {
    font-size: 1rem;
  }  
}

@media (max-width: 350px) {
  .deposit-popup {
    margin: 0 30px;
  }

  
.deposit-popup .paystack-btn, .manual-deposit-btn {
  margin: 5px 10px; 
}
}