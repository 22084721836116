.my-investments-page {
  padding: 20px;
  /* max-width: 1200px; */
  margin: 0 auto;
  
}

.investment-filters {
  display: flex;
  justify-content: flex-start; 
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.filter-btn {
  padding: 10px 10px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, padding 0.3s, font-size 0.3s;
  flex: 1;
  max-width: 300px;
  text-align: center;
  border-radius: 4px;
}

.filter-btn.active {
  background-color: #ffc700 !important;
  color: black !important;
}

.investment-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.investment-card {
  background-color: white;
  padding: 20px;
  color: #000;
  font-size: 1rem;
  border-radius: 10px;
    opacity: 1 !important;
  box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.1);
}

.investment-card h3 {
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.investment-card p {
  font-size: 0.73rem;
  margin-bottom: 5px;
}

.detail-value{
  color: #00ff00;
  font-size: 0.9rem;
}

.view-more-det{
  font-size: 0.8rem;
  color: #d04a0c;
  text-decoration: underline;
}

.no-investments {
  text-align: left;
  font-size: 1.2rem;
  color: #888;
  margin-top: 30px;
}

details summary {
  cursor: pointer;
  font-weight: bold;
  margin-top: 10px;
}

.asset-image {
  width: 100%;
  max-width: 300px;
  margin-top: 10px;
  border-radius: 10px;
}

@media screen and (min-width: 1024px) {
  .my-investments-page {
    text-align: left; /* Left align text for large screens */
  }
}

/* For smaller screens, reduce the button size */
@media screen and (max-width: 768px) {
  .filter-btn {
    font-size: 14px;  /* Reduce font size */
    padding: 8px 15px; /* Reduce padding */
  }
}

@media screen and (max-width: 480px) {
  .filter-btn {
    font-size: 12px;  /* Further reduce font size */
    padding: 6px 10px; /* Further reduce padding */
  }
}