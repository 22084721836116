.investment-banner {
  background-image: url("../Assets/investment-banner-bg.png");
  background-size: cover;
  color: white;
  padding: 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.banner-percent {
  font-size: 40px;
}

.investment-banner h2 {
  margin: 0;
  font-size: 24px;
}

.investment-banner img {
  height: 80px;
  border-radius: 10px;
}
