/* Verification Status */
.verification-status-heading {
  margin-bottom: 8px;
  margin-top: 12px;
}

.profile-content .personal-info{
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
  color: #3a3a3a;
}

.verified-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.verified-button.verified {
  background-color: #1de83e;
  color: #fff;
}

.verified-button.not-verified {
  background-color: #d9d9d9;
  color: #000;
}

.withdrawal-btn-container {
  max-width: 370px;
}

.withdraw-button {
  padding: 10px 20px;
  background-color: #f0b90b;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.withdraw-button:hover {
  background-color: #69c378;
}

.warning-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
  transition: opacity 0.3s ease-in-out;
}
