/* Staggered animations for different components */
.fade-enter {
  opacity: 0;
  transform: translateY(30px) scale(0.95) rotate(-2deg);
}
.fade-enter-active {
  opacity: 1;
  transform: translateY(0) scale(1) rotate(0deg);
  transition: opacity 1000ms, transform 1000ms ease-out;
}

.fade-exit {
  opacity: 1;
  transform: translateY(0);
}
.fade-exit-active {
  opacity: 0;
  transform: translateY(-30px);
  transition: opacity 1000ms, transform 1000ms ease-in;
}


/* existing / main styles for the page */
.overview-container {
  padding: 20px;
  margin: 0 auto;
  position: relative;
}

@media (max-width: 768px) {
  .overview-container {
    padding: 0;
    margin: 0 auto;
  }
}
