.withdrawal-container {
  padding: 20px;
  margin: 0 auto;
}

.profile-header p {
  color: #0e141c;
  font-size: 25px;
  font-family: poppins;
  font-weight: 600;
  line-height: 30px;
  word-wrap: break-word;
  margin: 40px 0;
}

.profile-header p span {
  color: #1de83e;
}

.account-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
}

.account-details input {
  border: none;
  height: 46px;
  max-width: 370px;
  padding: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: #f0eeee;
}

.button-wrapper{
  max-width: 370px;
}

.account-details input:focus {
  outline: none;
  background-color: #d9d9d9;
}

.account-details label[for="accountNumber"],
.account-details label[for="amount"],
.account-details label[for="pin"] {
  /* Your custom styles here */
  font-size: 16px;
  font-weight: 600;
  margin-bottom: -15px;
}

.account-details label[for="bankCode"] {
  font-size: 16px;
  font-weight: 600;
}

/* overlay */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Success and Error Messages */
.message {
  margin: 15px 0;
  padding: 10px 15px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  transition: opacity 0.3s ease;
}

.message.success {
  background-color: #d4edda;
  color: #155724;
  max-width: 370px;
  border: 1px solid #c3e6cb;
}

.message.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

/* Receiver's Name Styling */
.account-name {
  margin-top: 15px;
  padding-top: 15px;
  padding-bottom: 5px;
  background-color: #f0f8ff;
  color: #004085;
  font-size: 14px;
  max-width: 370px;
  width: "100%";
  /* font-weight: bold; */
  text-align: center;
  border: 1px solid #b8daff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}


@media (max-width: 768px) {
  .withdrawal-container {
    padding: 0;
  }

  .account-name {
    padding: 10px;
    font-size: 12px;
  }

  .account-details {
    grid-template-columns: 1fr;
  }

  .withdraw-button {
    width: 100%;
    max-width: 100%; /* Allow button to use full width on small screens */
  }

  select,
  input {
    width: 100%;
    max-width: 100%;
  }
}
