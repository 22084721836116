/* Profile Container */
.profile-container {
  padding: 20px;
  margin: 0 auto;
}

/* Tabs */

.profile-tabs {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  margin-bottom: 30px;
}

.tab{
  cursor: pointer;
  border: none;
  background: none;
  font-size: 14px;
  transition: border-bottom 0.3s ease-in-out;
}

.tab:hover {
  background-color: #f0b90b !important;
  color: white !important;
  padding: 0 4px;
}

/* to be used later */
/* .profile-tabs .tab {
  padding: 10px 20px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
} */

.profile-tabs .active-tab {
  /* background-color: #f0b90b; */
  color: #f0b90b;
}

.tab.active-tab {
  font-weight: bold;
  border-bottom: 3px solid #f0b90b;
  color: #f0b90b;
  position: relative;
  z-index: 0;
  top: 2px;
  width: 30%;
}

.profile-buttons {
  margin-top: 20px;
}

.update-button {
  padding: 12px 30px;
  background-color: #f0b90b;
  border: none;
  color: white;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.update-button:hover {
  background-color: #d8a30a;
}

/* transaction PIN styling */
.pin-input-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 20px;
}

.toggle-pin-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #8c8a8a;
  color: #fff;
}

.submit-pin-button {
  padding: 10px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #1de83e;
  color: #fff;
}

.pin-input-fields {
  display: flex;
  justify-content: center;
  align-items: start;
  margin-top: 10px;
}

.pin-input-fields .pin-input-item,
.confirm-pin-input-fields .pin-input-item {
  width: 40px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0 5px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.submit-pin-button {
  display: block;
  margin-top: 4px;
}

.pin-input-item.active {
  background-color: #f0f0f0;
}

.submit-address-btn {
  border: none;
  padding: 10px 30px;
  margin-top: 4px;
  border-radius: 4px;
  color: white;
  background-color: #1de83e;
}

@media (max-width: 768px) {
  .profile-container {
    padding: 0px;
    margin: 0 auto;
    border-radius: 8px;
  }
  .profile-tabs {
    justify-content: center;
  }

  .profile-tabs .tab {
    padding: 5px 10px;
    font-size: 10px;
  }

  .tab.active-tab {
    width: 50.33%; /* Adjusted width to fit 3 tabs in a row */
    border-bottom: none;
    top: 0;
    border-bottom: 3px solid #f0b90b !important;
    color: #f0b90b !important;
  }

  .verification-status {
    margin-top: 120px;
  }

  .verified-button,
  .withdraw-button {
    max-width: 260px;
  }
}
