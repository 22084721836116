.profile-content{
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
}

/* Input Fields */
.profile-inputs {
  display: grid;
  grid-template-columns: repeat(2, minmax(150px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}


.profile-inputs input {
  border: none;
  height: 46px;
  max-width: 370px;
  padding: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: #f0eeee;
}

.profile-inputs input:focus {
  outline: none;
  background-color: #d9d9d9;
}

.profile-inputs label {
  font-weight: 600;
  margin-bottom: -15px;
  color: #3a3939;
}

@media (max-width: 768px) {
  .profile-inputs {
    grid-template-columns: 1fr;
  }
}
