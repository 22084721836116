.signup-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  background-color: #1c1c1c;
  background-image: url("/src/Assets/onboarding-bg.png");
  background-size: cover;
  padding: 0 60px;
  margin-top: 0 !important;
  
}

.left-content {
  flex: 1;
  color: white;
  max-width: 50%;
  opacity: 0;
  transform: translateX(-100%);
  transition: all 1s ease;
}

.centered-logo {
  display: block;
  margin: 30px auto;
}


.slide-in-left {
  transform: translateX(0);
  opacity: 1;
}

.right-form {
  flex: 1;
  display: flex;
  justify-content: center;
  max-width: 50%;
  opacity: 0;
  transform: translateX(100%);
  transition: all 1s ease;
  margin-top: 30px;
}

.slide-in-right {
  transform: translateX(0);
  opacity: 1;
}

.form-card {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.form-card h2 {
  margin-bottom: 10px;
}

.form-card p {
  margin-bottom: 12px;
  color: #666;
}

.form-card form {
  display: flex;
  flex-direction: column;
}

.form-card form label {
  margin-bottom: -15px !important;
}

.form-card form input {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.form-card button {
  background-color: #ffd700;
  color: white;
  padding: 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.form-card button:hover {
  background-color: #e5b800;
}

.forgot-password {
  display: block;
  color: #ffd40b;
  font-size: 13px;
  font-weight: 600;
  text-align: right;
  margin-top: -10px;
  margin-bottom: 20px;
  line-height: 15.6px;
  word-wrap: break-word;
}

.forgot-password:hover {
  text-decoration: underline;
}


.create-account-text {
  margin-top: 20px;
  color: #666;
}

.create-account-text a {
  color: #00ff00;
  text-decoration: none;
}

.create-account-text a:hover {
  text-decoration: underline;
}

.hello-text {
  color: #ffd700;
  font-size: 48px;
  font-weight: bold;
  font-family: "Damion";
}

.welcome-text {
  font-size: 36px;
  font-weight: bold;
}

/* password input with eye icon */
.password-input-container {
  position: relative;
  width: 100%;
}

.password-input-container input,
.text-input-container input {
  width: 100%;
  padding-right: 40px;
  box-sizing: border-box;
}

.eye-icon {
  position: absolute;
  color: #0e141c;
  font-size: 22px;
  margin-top: 25px;
  right: 24px;
  transform: translateY(-50%);
  cursor: pointer;
}

/* Resend OTP Styles */
.resend-otp {
  margin-top: 15px;
  font-size: 14px;
  text-align: center;
  color: #555;
}

.resend-btn {
  background: transparent !important;
  border: 1px solid #00ff00 !important;
  color: #00ff00 !important;
  padding: 5px 20px !important;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease !important;
}

.resend-btn:hover {
  background-color: #00ff00 !important;
  color: white !important;
  transform: scale(1.05) !important;
}

.resend-btn:disabled {
  border: none !important;
  background-color: #ccc !important;
  color: #666 !important;
  cursor: not-allowed !important;
  transform: none !important;
}


/* asterisk */
.asterisk {
  color: red;
}

/* form error message */
.error-message {
  color: #ff0000 !important;
  font-size: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .signup-container {
    flex-direction: column;
    text-align: center;
    padding: 0 30px;
  }

  .left-content,
  .right-form {
    max-width: 100%;
    transform: translateX(0);
    opacity: 1; /* No slide-in animation on small screens */
  }

  .left-content {
    margin-bottom: 20px; /* Add some space between content and form */
  }

  .form-card {
    max-width: 100%;
    margin: 0 auto; /* Center the form on small screens */
  }

  .hello-text {
    font-size: 30px; /* Adjust text size for smaller screens */
    font-family: "Damion";
  }

  .welcome-text {
    font-size: 20px;
  }

  .eye-icon {
    right: 10px;
  }
}

/* to fix the desktop mode on the phone */
/* Tablet */

@media (min-width: 769px) and (max-width: 1024px) {
  .signup-container {
  padding: 0 40px;
  }
  
  .form-card {
  max-width: 350px;
  }
  }
  
  /* Desktop */
  
  @media (min-width: 1025px) {
  .signup-container {
  padding: 0 60px;
  }
  
  .form-card {
  max-width: 400px;
  }
  }
  
  /* Additional adjustments */
  
  .signup-container {
  box-sizing: border-box;
  }
  
  .form-card {
  box-sizing: border-box;
  }
  