/* Content wrapper to take remaining space */
.content-wrapper {
  flex-grow: 1; /* Allows this element to expand and take available space */
  padding: 20px;
  margin-left: 250px; /* Adjust margin to the width of the fixed sidebar */
  min-height: 100vh;
  box-sizing: border-box;
}

/* Ensure layout remains responsive */
@media (max-width: 768px) {
  .layout-container {
    flex-direction: column; /* Stack sidebar and content on small screens */
  }

  .sidebar {
    position: absolute; /* Change to absolute for easier toggling */
    width: 200px;
  }

  .content-wrapper {
    margin-left: 0; /* Remove left margin for small screens */
    padding-top: 20px; /* Add top padding to avoid overlap with the sidebar */
  }
}