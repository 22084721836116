* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'poppins', sans-serif;
}

a{
  text-decoration: none;
}

li {
  list-style:none;
}

body {
  margin-top: 70px;
}

/* this is for proper desktop site viewing on phone*/
html, body {
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  width: 100%;
  min-height: 100vh;
}
