
/* Profile Picture */
.profile-picture {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 20px 0;
}

.profile-picture img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.camera-btn {
  position: absolute;
  bottom: -8px;
  right: 15px;
  background-color: #f0b90b;
  padding: 5px;
  border: none;
  height: 25px;
  width: 25px;
  border-radius: 50%;
}

@media (min-width: 768px) {
  .profile-picture img {
    width: 80px;
    height: 80px;
  }

  .camera-btn {
    bottom: 30px;
  }
}